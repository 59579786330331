import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => {
  return <Bio>{children}</Bio>;
};

const Bio = styled.div`
  display: grid;
  height: fit-content;
  gap: 1.5rem;
`;
