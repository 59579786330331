import React from 'react'
import styled from 'styled-components'
import { isEmpty } from 'lodash'

interface Props {
  companies?: string[]
}

const PreviousCompanies: React.FC<Props> = ({ companies }) => {
  return (
    <StyledPrevious>
      {!isEmpty(companies) &&
        companies?.map(company => {
          return (
            <CompanyWrapper key={company}>
              <Company src={company} alt={company} />
            </CompanyWrapper>
          )
        })}
    </StyledPrevious>
  )
}

const StyledPrevious = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 72px);
  gap: 1.5rem;
  width: 70%;
  user-select: none;
`

const CompanyWrapper = styled.div``

const Company = styled.img``

export default PreviousCompanies
