import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Link } from 'gatsby';
import { LogIn } from 'react-feather';
import { Button } from '../../Button';

interface Props {
  url: string;
}

export default ({ url }: Props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Link to={url} style={{ marginBottom: '3rem' }}>
      <Button
        action="Back"
        btnHeight="2.5rem"
        icon={<LogIn style={{ transform: 'scale(-1,-1)' }} />}
        iconSide="left"
        bgColor={themeContext.colors.grey400}
      />
    </Link>
  );
};
