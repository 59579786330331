import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ text }: PropsWithChildren<{ text: string }>) => {
  return <Role>{text}</Role>;
};

const Role = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.grey700};
`;
