import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

const Heading: React.FC<Props> = ({ text }) => {
  return <StyledHeading>{text}</StyledHeading>;
};

const StyledHeading = styled.h1`
  font-weight: 600;
  margin: 0;
  line-height: 1;
`;

export default Heading;
