import { PageProps, graphql } from 'gatsby'
import * as React from 'react'
import { FluidObject } from 'gatsby-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Grid from '../components/OurPeople/utils/Grid'
import Heading from '../components/OurPeople/utils/Heading'
import PreviousCompanies from '../components/OurPeople/utils/PreviousCompanies'
import Headshot from '../components/OurPeople/utils/Headshot'
import Bio from '../components/OurPeople/utils/Bio'
import Content from '../components/OurPeople/utils/Content'
import Container from '../components/Container'
import Department from '../components/OurPeople/utils/Department'
import Role from '../components/OurPeople/utils/Role'
import Information from '../components/OurPeople/utils/Information'
import BG from '../components/OurPeople/utils/BG'
import Back from '../components/OurPeople/utils/Back'

import Footer from '../components/Footer/Footer'
import { SeoObject, Site } from '../components/Site'

interface Query {
  data: {
    mdx: {
      frontmatter: {
        id: number
        title: string
        name: string
        seoDescription: string
        featureImg: {
          childImageSharp: {
            fluid: FluidObject
          }
          publicURL: string
        }
      }
      body: string
    }
  }
}

// pass common components
const shortcodes = {
  Container,
  Grid,
  Heading,
  PreviousCompanies,
  Headshot,
  Bio,
  Department,
  Information,
  Content,
  BG,
  Role,
  Back
}

const Profile: React.FC<PageProps & Query> = props => {
  const { mdx } = props.data
  const seo: SeoObject = {
    title: mdx.frontmatter.title,
    image: mdx.frontmatter.featureImg.publicURL,
    description: mdx.frontmatter.seoDescription
  }

  return (
    <Site seo={seo} {...props}>
      <MDXProvider components={{ ...shortcodes }}>
        <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <Footer />
    </Site>
  )
}

export const pageQuery = graphql`
  query MyQuery($uid: String!) {
    mdx(frontmatter: { slug: { eq: $uid } }) {
      frontmatter {
        id
        title
        name
        seoDescription
        featureImg {
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
      body
    }
  }
`

export default Profile
