import React from 'react'
import styled from 'styled-components'

export default ({ text }: { text: string | string[] }) => {
  return Array.isArray(text) ? (
    <Wrapper>
      {text.map(deparment => (
        <Department key={deparment}>{deparment}</Department>
      ))}
    </Wrapper>
  ) : (
    <Department>{text}</Department>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  width: fit-content;
`

const Department = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.purple};
  font-size: 0.875rem;
  letter-spacing: 1px;
  border: 1px solid ${({ theme }) => theme.colors.purple};
  width: fit-content;
  padding: 4px 16px;
  border-radius: 30px;
  user-select: none;
`
