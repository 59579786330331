import React from 'react';
import styled from 'styled-components';

interface Props {
  children?: React.ReactNode;
}

const Grid: React.FC<Props> = ({ children }) => {
  return <StyledGrid>{children}</StyledGrid>;
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  line-height: 1.9;
  padding: 9rem 1.5rem 0;

  @media all and (max-width: 52.5em) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export default Grid;
