import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => {
  return <BG>{children}</BG>;
};

const BG = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: ${({ theme }) => theme.colors.grey200};
  display: flex;
  align-items: center;
  z-index: 10;

  @media all and (max-width: 48em) {
    height: auto;
  }
`;
