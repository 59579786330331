import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export default ({ children }: PropsWithChildren<{}>) => {
  return <Information>{children}</Information>;
};

const Information = styled.div`
  display: grid;
  gap: 1.5rem;
`;
