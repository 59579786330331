import React from 'react';
import styled from 'styled-components';
import Img, { FluidObject } from 'gatsby-image';

interface Props {
  src: {
    childImageSharp: {
      fluid: FluidObject;
    };
  };
  alt: string;
}

const ImgWrapper = styled.div`
  border-radius: 50%;
  max-width: 10rem;
  width: 10rem;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  overflow: hidden;
`;

export default ({ src, alt }: Props) => (
  <ImgWrapper>
    <Img fluid={src.childImageSharp.fluid} alt={alt} />
  </ImgWrapper>
);
